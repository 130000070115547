@font-face {
    font-family: 'Aeonik';
    src: url('assets/fonts/Aeonik-Regular.eot');
    src: url('assets/fonts/Aeonik-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Aeonik-Regular.woff2') format('woff2'),
        url('assets/fonts/Aeonik-Regular.woff') format('woff'),
        url('assets/fonts/Aeonik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('assets/fonts/Aeonik-Medium.eot');
    src: url('assets/fonts/Aeonik-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Aeonik-Medium.woff2') format('woff2'),
        url('assets/fonts/Aeonik-Medium.woff') format('woff'),
        url('assets/fonts/Aeonik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeonik';
    src: url('assets/fonts/Aeonik-Bold.eot');
    src: url('assets/fonts/Aeonik-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Aeonik-Bold.woff2') format('woff2'),
        url('assets/fonts/Aeonik-Bold.woff') format('woff'),
        url('assets/fonts/Aeonik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LivornoWeb';
    src: url('assets/fonts/LivornoWeb-Bold.eot');
    src: url('assets/fonts/LivornoWeb-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/LivornoWeb-Bold.woff2') format('woff2'),
        url('assets/fonts/LivornoWeb-Bold.woff') format('woff'),
        url('assets/fonts/LivornoWeb-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Livorno';
    src: url('assets/fonts/Livorno-Bold.eot');
    src: url('assets/fonts/Livorno-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/Livorno-Bold.woff2') format('woff2'),
        url('assets/fonts/Livorno-Bold.woff') format('woff'),
        url('assets/fonts/Livorno-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}